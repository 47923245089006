import axios from 'axios'
import React, { useState } from 'react'

function SavePost() {
    const [postId, setPostId] = useState('')
    const handleSavePost = async() => {
        await axios.post('https://sell-skill.com/api/endpoints/savePost', {postId})
    }
  return (
    <div>
      <input 
      placeholder='eneter postId'
      onChange={(e) => setPostId(e.target.value)}
      />
      <button onClick={ handleSavePost}>save post</button>
    </div>
  )
}

export default SavePost
